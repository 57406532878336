import React from "react";
import Heading from "../../Helper/Heading";

function ExecutiveCommitee() {
  return (
    <div className="container">
      <div className="text-center mb-5">
        <Heading
          title={"Executive Committee"}
          classNames={"text-uppercase font-weight-bold"}
        />
      </div>

      <div className="d-flex justify-content-around flex-column flex-sm-row">
        <div className="padding-5">
          <p>
            Dr. Sanjay Tripathi <br />
            Dr. Rajesh Hyderabadi
            <br />
            Dr. Bhupesh Shah
            <br />
            Dr. Nilesh Dutt
            <br />
            Dr. Savita Jindal
            <br />
            Dr. Ghanshyam Borisagar
            <br />
            Dr. Manoj Singh
            <br />
            Dr. Janak Bhavsar
            <br />
            Dr. Ajay Jain
            <br />
            Dr. Ajay Shah
          </p>
        </div>

        <div className="padding-5">
          <p>
            Dr. Kashmira Jhala
            <br />
            Dr. Viral Shah
            <br />
            Dr. Hetal Shah
            <br />
            Dr. Meghna Patel
            <br />
            Dr. Harendra Thakkar
            <br />
            Dr. Vishakha Kapadiya
            <br />
            Dr. Gajendrasinh Rathod
            <br />
            Dr. Abhishek Prajapati
            <br />
            Dr. Rudra Parmar
            <br />
            Dr. Minesh Patel
          </p>
        </div>

        <div className="padding-5">
          <p>
            Dr. Vipul Sengal <br />
            Dr. Rahul Jalan
            <br />
            Dr. Kalpesh Panchal
            <br />
            Dr. Kaushal Bhavsar
            <br />
            Dr. Sweta Gargiya
            <br />
            Dr. Mitesh Dave
            <br />
            Dr. Jayant Trivedi
            <br />
            Dr. Jaykumar Mehta
            <br />
            Dr. Avkash Patel
            <br />
            Dr. Nisarg Patel
          </p>
        </div>

        <div className="padding-5">
          <p>
            Dr. Vaishal Sheth
            <br />
            Dr. Divyang Patel
            <br />
            Dr. Ilesh Patel
            <br />
            Dr. Darpan Gandhi
            <br />
            Dr. Dhaval Darji
            <br />
            Dr. Sujal Patel
            <br />
            Dr. Keyur Patel
            <br />
            Dr. Swetang Jani
            <br />
            Dr. Dharit Shah
          </p>
        </div>
      </div>
    </div>
  );
}

export default ExecutiveCommitee;
