import React from "react";
import Heading from "../../Helper/Heading";

function Table() {
  return (
    <div className="container">
      <Heading
        title={"TES OFFICE BEARERS"}
        classNames="text-center text-uppercase font-weight-bold"
      />
      <div className="d-flex justify-content-around mt-5 flex-column flex-sm-row">
        <div className="padding-5">
          <h5>PRESIDENT</h5>
          <p>Dr. S K Sarkar</p>
          <h5>VICE PRESIDENT</h5>
          <p>
            Dr. Mukesh Sharma <br />
            Dr. K B Gupta
          </p>
          <h5>SECRETARY</h5>
          <p>Dr. Ram Babu Gupta</p>
          <h5>TREASURER</h5>
          <p>Dr. R P Meena</p>
          <h5>JT. SECRETARY</h5>
          <p>
            Dr. Ujjwal Parakh <br /> Dr. Nalin Joshi
          </p>
          <h5>ZONAL CHAIR PERSON</h5>
          <p>
            Dr. Bhabatosh Biswas - East
            <br /> Dr. Amita Abhay Nene - West
            <br /> Dr. H J Singh - North
            <br /> Dr. Nasser Yusuf - South
          </p>
        </div>

        <div className="padding-5">
          <h5>GOVERNING COUNCIL</h5>
          <p>
            Dr. P P Khandelwal <br />
            Dr. Mukesh Bhatnagar
            <br />
            Dr. Anadi Mathur
            <br />
            Dr. Vinod Singh
            <br />
            Dr. Nikhil Sarangdhar
            <br />
            Dr. Ankit Bansal
            <br />
            Dr. Nitin Jain
          </p>

          <h5>ADVISORY BOARD</h5>
          <p>
            Dr. Karan Singh Yadav <br />
            Dr. Goutam Sen
            <br />
            Dr. Rajan Santosham
            <br />
            Dr. Arvind Kumar
            <br />
            Dr. Manohal Lal Gupta
            <br />
            Dr. Ravindra Deewan
            <br />
            Dr. N K Jain
            <br />
            Dr. R Narasimhan
          </p>
        </div>

        <div className="padding-5">
          <h5>FOUNDER MEMBERS</h5>
          <p>
            Dr. Anula Sisodia <br />
            Dr. Navin Bhargava
            <br />
            Dr. Sushant Sarkar
            <br />
            Dr. Rajendra K Garg
            <br />
            Dr. Pooja Bhargava
            <br />
            Dr. Yugal Tak
            <br />
            Maj. Gen. (R) A Banerjee
            <br />
            Mr. Robin Sarkar
            <br />
            Mr. Vinod Pareek
            <br />
            Mr. Manoj Ramchandani
            <br /> MR. R L Saraf
          </p>
        </div>
      </div>
    </div>
  );
}

export default Table;
