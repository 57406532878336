import React, { useState } from "react";
import Slider from "react-slick";

import Heading from "../../Helper/Heading";

const Schedule = () => {
  const [id, setActiveContent] = useState(1);

  const tabHandler = (id) => {
    setActiveContent(id);
  };

  return (
    <div className="bg-secondary py-5" id="schedule">
      <div className="container">
        <div className="text-center">
          <Heading title="Schedule" />
        </div>
        <div className="Tabs mt-5">
          <div className="Tab__Pills">
            <ul className="list-unstyled d-flex justify-content-center">
              <li
                className={`text-center ` + (id === 1 ? "Active__Tab" : "")}
                onClick={() => tabHandler(1)}
              >
                <p className="font-weight-bold text-uppercase lead mb-0">
                  Day 1
                </p>
                <small>17 December 2021, Friday </small>
              </li>
              <li
                className={
                  `text-center mx-3 ` + (id === 2 ? "Active__Tab" : "")
                }
                onClick={() => tabHandler(2)}
              >
                <p className="font-weight-bold text-uppercase lead mb-0">
                  Day 2
                </p>
                <small>18 December 2021, Saturday</small>
              </li>
              <li
                className={
                  `text-center mx-3 ` + (id === 3 ? "Active__Tab" : "")
                }
                onClick={() => tabHandler(3)}
              >
                <p className="font-weight-bold text-uppercase lead mb-0">
                  Day 3
                </p>
                <small>19 December 2021, Sunday</small>
              </li>
            </ul>
          </div>
          <div className="Tab__content">
            {id === 1 && (
              <>
                <h5 className="mt-4 text-center">WORKSHOP</h5>
                <div className="mb-3 text-center">
                  <small>
                    SGVP Holistic Hospital Sarkhej - Gandhinagar Highway,
                    Charodi, Ahmedabad, Gujarat 382481
                  </small>
                </div>
                <div className="d-flex Tab__content__List flex-column flex-sm-row">
                  <ul>
                    <lh className="lead font-weight-bold mx-2">
                      9:00 am to 1:00 pm Live Procedure
                    </lh>
                    <li className="lead font-weight-bold">
                      Case 1: Basic bronchoscopy including BAL
                    </li>
                    <li className="lead font-weight-bold">
                      Case 2: Bronchoscopic biopsy (EBB and TBLB)
                    </li>
                    <li className="lead font-weight-bold">
                      Case 3: Conventional TBNA
                    </li>
                    <li className="lead font-weight-bold">
                      Case 4: Linear EBUS
                    </li>
                    <li className="lead font-weight-bold">
                      Case 5: Radial EBUS/Cryo TBLB
                    </li>
                    <li className="lead font-weight-bold">
                      Case 6: Therapeutic Bronchoscopy
                    </li>
                    <li className="lead font-weight-bold">
                      Case 7: CRE Balloon Dilatation
                    </li>

                    <li className="lead font-weight-bold">
                      Case 8: Medical Thoracoscopy
                    </li>

                    <li className="lead font-weight-bold">
                      Case 9: Video Assisted Thoracic Surgery
                    </li>

                    <li className="lead font-weight-bold">
                      Case 10: Decortication / Lung resection
                    </li>

                    <li className="lead font-weight-bold">
                      Case 11: Indwelling pleural catheter insertion
                    </li>
                  </ul>
                  <ul className="ml-sm-auto">
                    <lh className="lead font-weight-bold mx-2">
                      2:00 to 6:00 pm Hands on training
                    </lh>

                    <li className="lead font-weight-bold">
                      Station 1: Normal Anatomy on Mannequin
                    </li>
                    <li className="lead font-weight-bold">
                      Station 2: Foreign Body on Mannequin
                    </li>
                    <li className="lead font-weight-bold">
                      Station 3: Conventional TBNA on Mannequin
                    </li>
                    <li className="lead font-weight-bold">
                      Station 4: EBUS TBNA on Mannequin
                    </li>
                    <li className="lead font-weight-bold">
                      Station 5: Electrocautery on animal model
                    </li>
                    <li className="lead font-weight-bold">
                      Station 6: Cryo-Biopsy on animal model
                    </li>
                    <li className="lead font-weight-bold">
                      Station 7: Bronchial Thermal Vapour Ablation
                    </li>
                    <li className="lead font-weight-bold">
                      Station 8: Virtual Navigation Bronchoscopy
                    </li>
                    <li className="lead font-weight-bold">
                      Station 9: Rigid Bronchoscopy and Stenting
                    </li>
                    <li className="lead font-weight-bold">
                      Station 10: Pleuroscopy on Mannequin
                    </li>
                    <li className="lead font-weight-bold">
                      Station 11: Indwelling pleural catheter insertion
                    </li>
                  </ul>
                </div>
              </>
            )}
            {id === 2 && (
              <>
                <h5 className="mt-4 text-center">SCIENTIFIC PROGRAM</h5>

                {/* <div className="container">
                  <ul>
                    <li className="d-flex w-100">
                      <p className="w-sm-75 lead font-weight-bold">Keynote</p>
                      <p className=" w-sm-25 lead font-weight-bold">Speaker</p>
                    </li>
                    <li className="d-flex w-100 flex-wrap flex-sm-row">
                      <p className="w-sm-75">
                        <span className="d-sm-none font-weight-bold">
                          Key Note
                        </span>
                        Consequences countering sequencing Post Covid lung
                        sequelae - Can surgery influence the outcome?
                      </p>
                      <p className=" w-sm-25">
                        <span className="d-sm-none font-weight-bold">
                        Speaker</span>
                        Dr. Naseer Yusuf
                      </p>
                    </li>
                    <div className="divider d-sm-none"></div>
                  </ul>
                </div> */}

                <div className="Tab__content__List">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="lead font-weight-bold list-unstyled d-none d-sm-block">
                          Keynote
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="lead font-weight-bold list-unstyled d-none d-sm-block">
                          Speaker
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Consequences countering sequencing Post Covid lung
                          sequelae - Can surgery influence the outcome?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Naseer Yusuf
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Discourse of distinction. Pleuroscopy - A under
                          utilized tool yet ?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Mukesh Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 1 - Bridal bond with Bronchoscopy: Get ready
                          to be groomed
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          History of Bronchoscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Atul C Mehta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Pre procedure preparation of patient and suit;
                          vigilance during and after procedure
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Ujaas Dawar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Basics of bronchoscopy and routine bronchoscopic
                          sampling
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Vinod Joshi
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchosocopy - Challenging scenarios!
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Pratibha Gogia
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 2- Apical stream - Streamlining of strings.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Over view of central airway obstruction
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Anil K Singh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Ablation therapy for Malignany CAO
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Harikishan Gonuguntla
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Management of benign central airway obstruction
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Dipak Mukherjee
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Fistula of central airway - IP's call or Surgical call
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Rajan Santosham
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 3 - Acoustic ride that guides through tubal
                          tunnels.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Basic principles of Ultrasounds and its evolution in
                          bronchology and perception of mediastinal anatomy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr Veerottam Tomar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          EBUS - Sampling of Nodes Vs Conventional TBNA -
                          Sampling - Techniques and tricks
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Richa Gupta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Comparison of EBUS TBNA Needle - Choosing one on
                          another by Evidence
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Tinku Joseph
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          EBUS - TBCNB (Transbronchial Cryo Nodal Biopsies) - Is
                          it really a game changer ?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Amit Dhamija
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Role of EBUS in staging of lung cancer
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Harikishan Gonuguntla
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Panel Discusssion - Conventional Vs EBUS TBNA
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Firoj Ghanchi
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 4 - Costal port at pleural coast.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Cryo Biopsy of Pleura - Expanding horizon of utility
                          of Cryotherapy - Does it really mean ?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Sonia Dalal
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Pleurodesis and its approach in various cause of
                          pleural effusion and choosing correct agent
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Nitin Jain
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Pleuroscopy in management of pleural diseases
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Anil Saxena
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Decortications - Thoracoscopy advances over
                          Thoracotomy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Pranav Modi
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Panel Discusssion - Medical Vs Surgical Thoracoscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Alpesh Parmar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 5 - Curetting cure, a respite to respiratory
                          restrains.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Mediastinoscopy - Art that near to die ?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Somesh Chnadra
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Innovations in thoracic surgery - The future
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Rajeev Santosam
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Thoracoscopic Lung resection - Fine art
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Mahesh Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Surgical approach for Pancost Tumors or thoracic inlet
                          tumors
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Abhinav Jain
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 6 - Log in to unlock blocked lock
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchial Thermoplasty in era of Biologics
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Mohammad Munnavar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchial Thermoplasty - Indian Experience
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Sheetu Singh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Emphysema - Bronchoscopic Intervention - Reality or
                          Myths
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Pushan Jani
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchial thermal vapour therapy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Prof Bing Lam
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Tracheobronchomalasia - Stents (Metallic / Silicon
                          Stent Vs Pneumatic stents Vs Operative Mx)
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Richa Gupta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 7 - Masters' Marvel Mix
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          The journey of Single use Bronchoscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Anil K Singh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          DISE - Chest physician domain or ENT
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Naveen Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          PAP Management
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Jeenam Shah
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          NGS Era - How much sample you need ? - Oncologist
                          perspective
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Abhishek Kakroo
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Virtual bronchoscopy - A Radio Guide
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Amit Gupta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Interventional Pulmonology in pallitive care of lung
                          cancer
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Richa Gupta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Panel Discusssion - Bronchopleural Fistula IP Vs
                          Surgeon
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Manjunath Bale
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 8 - Notes on negotiating nodes of nodules.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Pulmonary nodules - Conventional Approach
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Atul C Mehta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Utility of Radial EBUS for Pulmonary Nodules -
                          Diagnostic to therapeutics
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Ujjwal Parakh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Navigation Bronchoscopy - Assisting tools or
                          Overriding experts
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Viswesvaran
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Metastasectomy vs Cryoablation / Radiofrequency
                          ablation for Malignant nodule
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Dipak Mukhrjee
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchoscopic therapeutic treatment of PPN in year
                          2021 - Mile stone to pass
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Kedar Hibre
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
            {id === 3 && (
              <>
                <h5 className="mt-4 text-center">SCIENTIFIC PROGRAM</h5>
                <div className="Tab__content__List">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="lead font-weight-bold list-unstyled d-none d-sm-block">
                          Keynote
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="lead font-weight-bold list-unstyled d-none d-sm-block">
                          Speaker
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Itinerary of innovations of endoscopic odyssey of
                          airways.
                          <br /> - Newer innovations in Bronchoscopy
                          <br /> - Diagnostic and therapeutics
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Anant Mohan
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 1 - Learning by end of the year
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Year in review of Stents / Neotrachea
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Tinku Joseph
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Year in review of pleuroscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. M. Munavvar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Year in reivew of Cryo Biopsy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Mahendra Kumar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Year in review of CAO
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Sunil Kumar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Year in review BAL in DPLD - Trajectory beyond tubal
                          terrains
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Manoj Yadav
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 2 -Feat that tough my feet step learning.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Indigenous pleuroscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Hanmant Varudkar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          What went wrong?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Harikishan Gonuguntla
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Foreign Body removal - risk worth taking?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Pratibha Singhal
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Mistakes I have made as an Interventional
                          Pulmonologist <br /> - trails and tribulations
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Atul Mehta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 3 - Endoscopic ventures, unveild.
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchoscopy in critically ill patient
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Manoj Singh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bridging brech makes breathing breed - FOB in ICU
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Nihar Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Paucity, noa pause for Pleuroscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Abhishek Prajapati
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Trading through tunnels
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Pradip Dabhi
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Cryo biopsy: The heat is on!
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Arpan Shah
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Journey through a strawberry farm
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Rushi Rabari
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Interesting pearls in bronchology
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Rajendra Prasad
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Defuse confusion of diffuse and distant dictations -
                          Cryo Bx for DPLD and PPL
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Umang Shah
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Parental probe of paediatric plugs - FOB for pediatric
                          FB
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Tushar S. Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchoscopy BAL for diagnostic yield for DPLD
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Naman Patel
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 4 - Revised transcript of erased script
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Technicality of lung transplants - Transplant
                          surgeon's view
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Sandeep Attawar
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Timeline of Complications of lung treansplant by
                          Transplant Physician
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Apar Jindal
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Airway complication and Its Management - Does IP get
                          over Surgeons
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Vijaykumar
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Chennamchatti Interventional pulmonology for beginners
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Bharat Toshniwal
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="font-weight-bold list-unstyled">
                          Symposium 5 - Synchronising signature of success
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled"></li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Rigid Bronchoscopy - Need of time to learn a forgotten
                          art?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. S. Yuvrajan
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Challenges of Bronchoscopy in upcoming Pandemic - What
                          we learn from Covid - 19
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. K K Sharma
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Hemoptysis and Bronchoscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Atul Luhadia
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Bronchoscopy in Tuberculosis
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. H. J. Singh
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          New era of disposable Bronchoscopy
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Dipak Viradia
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          Conventional TBNA and TBLB :- starting epitome of IP !
                          - Should we forget them in the era of EBUS AND CRYO
                          biopsy ?
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr Amit Dave
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          "Trail blazing the smaller airways: from disease
                          controller to disease eradicator?"
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Sweta Gargia
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          "Intrapleural Fibrinolytic vs thoracoscopic
                          deloculation : Which one is better"
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr Jitendra Kotadiya
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key NoSpeaker
                          </span>
                          "Use of dry seal valved chest drain in ambulatory
                          management of persistent air-leak: a single center
                          experience"
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr. Jaykumar Mehta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row align-items-start justify-content-center">
                    <div className="col-12 col-sm-8">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Key Note
                          </span>
                          "Complications of Thoracoscopy - Managing the Unseen"
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                      <ul>
                        <li className="list-unstyled">
                          <span className="font-weight-bold lead my-1 d-block d-sm-none">
                            Speaker
                          </span>
                          Dr Achal Parekh
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-5 text-center">
          <a
            className="ant-btn ant-btn-primary ant-btn-lg text-white"
            href={process.env.REACT_APP_EVENT_WEB_REGISTRATION_LINK}
          >
            Register Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
