import { Icon } from "antd";
import React from "react";
import brochurePdf from "../../../assets/pdf/brochure.pdf";
import { DownloadIcon } from "../../Helper/Icons";

export default function FloatedButton() {
  return (
    <div className="sticky-bottom-floated-container text-right">
      <div className="container">
        {" "}
        <a
          href={brochurePdf}
          target="_blank"
          className="ant-btn link font-weight-bold mb-2 floated-action-btn"
          rel="noopener noreferrer"
        >
          {" "}
          <DownloadIcon className="mr-1" /> BROCHURE{" "}
        </a>
        <br />
        <a
          className="ant-btn ant-btn-primary text-white floated-action-btn btn_add-user"
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_EVENT_WEB_REGISTRATION_LINK}
        >
          <Icon type="user-add" className="mr-1" />
          Register Now
        </a>
      </div>
    </div>
  );
}
