import React, { Component } from "react";
import Slider from "react-slick";
import Heading from "../../Helper/Heading";
import committeeData from "./committeeData";
import scientificCommitteeData from "./scientificCommitteeData";

// import DrRupeshShah from '../../../assets/images/commitees/dr.rupesh-shah.jpg';
// import DrAshwinShah from '../../../assets/images/commitees/dr.ashwin-shah.jpg';
// import DrDivyeshShah from '../../../assets/images/commitees/dr.divyesh-shah.jpg';
// import DrSamirRay from '../../../assets/images/commitees/dr.samir-ray.jpg';
// import DrBhikhuBhaiPatel from '../../../assets/images/commitees/dr.bhikhubhai-patel.jpg';
// import DrJagdishPatel from '../../../assets/images/commitees/dr.jagdish-patel.jpg';
// import DrSamirContractor from '../../../assets/images/commitees/dr.samir-contractor.jpg';
// import DrKalpeshJani from '../../../assets/images/commitees/dr.kalpesh-jani.jpg';
// import DrPankajKhandelwal from '../../../assets/images/commitees/dr.pankaj-khandelwal.jpg';

var settings = {
  dots: true,
  infinite: true,
  lazyLoad: true,
  speed: 2000,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        // dots: true
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        // dots: true
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite: true,
        // dots: true
      },
    },
  ],
};

export default class Committee extends Component {
  render() {
    return (
      <>
        <ul className="container pt-5 pb-4 pb-md-0" id="committee">
          <div className="text-center mb-5">
            <Heading title={"Committee"} />
          </div>
          <Slider {...settings}>
            {committeeData.map((member) => {
              return (
                <div className="text-center mb-3">
                  <div className="avatar-md-container mx-auto">
                    {member.img()}
                  </div>
                  <p className="mb-0 text-black mt-3 font-weight-bold lead">
                    {member.name}
                  </p>
                  <p style={{ lineHeight: "18px" }}>
                    <small>{member.designation}</small>
                  </p>
                </div>
              );
            })}
          </Slider>
        </ul>

        <ul className="container pt-5 pb-4 pb-md-0" id="committee">
          <div className="text-center mb-5">
            <Heading
              title={"Scientific & Workshop Committee"}
              classNames="text-uppercase font-weight-bold"
            />
          </div>
          <Slider {...settings}>
            {scientificCommitteeData.map((member) => {
              return (
                <div className="text-center mb-3">
                  {member.img()}
                  <p className="mb-0 text-black mt-3 font-weight-bold lead">
                    {member.name}
                  </p>
                  <p style={{ lineHeight: "18px" }}>
                    <small>{member.designation}</small>
                  </p>
                </div>
              );
            })}
          </Slider>
        </ul>
      </>
    );
  }
}
