import React from "react";

const Heading = ({ title, border, classNames }) => {
  return (
    <React.Fragment>
      {classNames ? (
        <h2 className={classNames}>{title}</h2>
      ) : (
        <h2 className="text-uppercase font-weight-bold">{title}</h2>
      )}

      {border === "start" ? (
        <div className="Heading__Border" />
      ) : (
        <div className="Heading__Border" style={{ margin: "0 auto" }} />
      )}
    </React.Fragment>
  );
};

export default Heading;
