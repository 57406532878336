import React from "react";

import drNareshPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1205.png";
import drNarendraRaval from "../../../assets/images/commitees/Tescon Committee/Rectangle 1212.png";
import drKRPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1219.png";
import drATLeuva from "../../../assets/images/commitees/Tescon Committee/Rectangle 1227.png";
import drTusharShah from "../../../assets/images/commitees/Tescon Committee/Rectangle 1235.png";
import drRNSolanki from "../../../assets/images/commitees/Tescon Committee/Rectangle 1242.png";
import drFirojGhanchi from "../../../assets/images/commitees/Tescon Committee/Rectangle 1263.png";
import drMukeshPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1249.png";
import drniteshShah from "../../../assets/images/commitees/Tescon Committee/Rectangle 1256.png";
import drRajBhagat from "../../../assets/images/commitees/Tescon Committee/Rectangle 1270.png";
import drVarunPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1277.png";
import drAnandPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1287.png";
import drGopalRaval from "../../../assets/images/commitees/Tescon Committee/Rectangle 1295.png";
import drTusharPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1302.png";

const ImageCommittee = ({ src }) => {
  return <img src={src} alt="Comittee" className="rounded-circle mx-auto" />;
};

const committeeData = [
  {
    name: "Dr. Naresh Patel",
    designation: "Patron",
    img: () => {
      return <ImageCommittee src={drNareshPatel} />;
    },
  },
  {
    name: "Dr. Narendra Raval",
    designation: "Patron",
    img: () => {
      return <ImageCommittee src={drNarendraRaval} />;
    },
  },
  {
    name: "Dr. K.R Patel",
    designation: "Patron",
    img: () => {
      return <ImageCommittee src={drKRPatel} />;
    },
  },
  {
    name: "Dr. A.T Leuva",
    designation: "Patron",
    img: () => {
      return <ImageCommittee src={drATLeuva} />;
    },
  },
  {
    name: "Dr. Tushar Shah",
    designation: "Patron",
    img: () => {
      return <ImageCommittee src={drTusharShah} />;
    },
  },
  {
    name: "Dr. R N Solanki",
    designation: "President",
    img: () => {
      return <ImageCommittee src={drRNSolanki} />;
    },
  },
  {
    name: "Dr. Firoj Ghanchi",
    designation: "Vice President",
    img: () => {
      return <ImageCommittee src={drFirojGhanchi} />;
    },
  },
  {
    name: "Dr. Mukesh Patel",
    designation: "Chairman",
    img: () => {
      return <ImageCommittee src={drMukeshPatel} />;
    },
  },
  {
    name: "Dr. Nitesh Shah",
    designation: "Co-Chairman",
    img: () => {
      return <ImageCommittee src={drniteshShah} />;
    },
  },
  {
    name: "Dr. Raj Bhagat",
    designation: "Co-Chairman",
    img: () => {
      return <ImageCommittee src={drRajBhagat} />;
    },
  },
  {
    name: "Dr. Varun Patel",
    designation: "Secretary",
    img: () => {
      return <ImageCommittee src={drVarunPatel} />;
    },
  },
  {
    name: "Dr. Anand Patel",
    designation: "Joint Secretary",
    img: () => {
      return <ImageCommittee src={drAnandPatel} />;
    },
  },
  {
    name: "Dr. Gopal Raval",
    designation: "Treasurer",
    img: () => {
      return <ImageCommittee src={drGopalRaval} />;
    },
  },
  {
    name: "Dr. Tushar Patel",
    designation: "Co-Ordinator",
    img: () => {
      return <ImageCommittee src={drTusharPatel} />;
    },
  }
];

export default committeeData;
