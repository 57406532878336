import React from "react";
import Banner from "./Banner/Banner";
import Navigation from "../Navigation/Navigation";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";
import Countdown from "./Countdown/Countdown";
import Schedule from "./Schedule/Schedule";
import Committee from "./Committee/Committee";

import "./home.scss";
import Fees from "./Fees/Fees";
import CityAttraction from "./CityAttrection/CityAttraction";
import FloatedButton from "./FloatedButton/FloatedButton";
import Table from "./Table/Table";

const Home = () => {
  return (
    <React.Fragment>
      <Banner />
      <Navigation />
      <WelcomeMessage />
      <Table />
      <Schedule />
      <Countdown />
      <Fees />
      <CityAttraction />
      <FloatedButton />
    </React.Fragment>
  );
};

export default Home;
