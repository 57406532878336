import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Footer from "./Footer/Footer";
import Home from "./Home/Home";

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Home/>
                <Footer/>
            </BrowserRouter>
        </React.Fragment>
    )
}

export default App;
