import React from "react";

import Heading from "../Helper/Heading";
import {
  LocationIcon,
  EmailIcon,
  PhoneIcon,
  DochtubLogo,
} from "../Helper/Icons";
import "./footer.scss";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="container py-5" id="contact">
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <Heading border={"start"} title={"Secretariat Office Contact"} />
            <div className="d-flex mt-3">
              <LocationIcon
                style={{
                  margin: "5px 5px 0 0",
                  color: "#70d1f4",
                }}
              />
              <address>
                Avron Hospital <br />
                4 Shantiniketan Park, <br />
                Near Sardar Patel Statue,
                <br /> Naranpura, Ahmedabad - 380013, Gujarat, India
              </address>
            </div>
            <div className="d-flex">
              <EmailIcon
                style={{
                  margin: "5px 5px 0 0",
                  color: "#70d1f4",
                }}
              />
              <a className="link">tescon2020@gmail.com </a>
            </div>
            <div className="d-flex mt-3"></div>
            <div className="d-flex mt-3">
              <PhoneIcon
                style={{
                  margin: "5px 5px 0 0",
                  color: "#70d1f4",
                }}
              />
              <p>
                Contact person
                <br />
                Dr. Varun Patel
              </p>
            </div>
            <PhoneIcon
              style={{
                margin: "5px 5px 0 0",
                color: "#70d1f4",
              }}
            />{" "}
            <a href="tel:+919879771079">(+91) 9879771079</a>,
            <a href="tel:+91992516682"> (+91) 9925166682</a>
          </div>
          <div className="col-sm-12 col-md-4 mt-4 mt-md-0">
            <Heading border={"start"} title={"Event Venue"} />
            <div className="d-flex mt-3">
              <LocationIcon
                style={{
                  margin: "5px 5px 0 0",
                  color: "#70d1f4",
                }}
              />
              <address>
                Narayani Heights Hotel & Club, <br />
                Bhat, Gandhinagar, Gujarat.
              </address>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 mt-4 mt-md-0">
            <Heading border={"start"} title={"Digital Partner"} />
            <a
              rel="noopener noreferrer"
              href="https://www.docthub.com/"
              className="d-inline-block link mt-4 mb-3 w-100 footer-logo-link"
              target="_blank"
            >
              <DochtubLogo />
            </a>
            <p className="mb-0">Empowering Healthcare Fraternity</p>
            <a
              rel="noopener noreferrer"
              href="https://www.docthub.com/"
              className="d-inline-block link"
              target="_blank"
            >
              www.docthub.com
            </a>
          </div>
        </div>
      </footer>
      <footer className="Bottom__Footer py-3">
        <div className="container">
          <div className="d-block d-md-flex justify-content-between">
            <div>
              <p className="mb-0">© 2021 All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
