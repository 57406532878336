import React, { Component } from "react";
import "./navigation.scss";
import Scrollspy from "react-scrollspy";

export default class Navigation extends Component {
  state = {
    scroll: false,
  };

  handleScroll = (event) => {
    const navigation = document.querySelector("nav");
    const navTop = navigation.offsetTop;

    if (window.pageYOffset > navTop) {
      this.setState({
        scroll: true,
      });
      document.body.style.paddingTop = navigation.offsetHeight + "px";
    } else {
      this.setState({
        scroll: false,
      });
      document.body.style.paddingTop = 0;
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <nav
        className={
          `d-none d-sm-none d-md-none d-lg-block navbar navbar-expand-lg navbar-light ` +
          (this.state.scroll ? "fixed-top" : "")
        }
      >
        <div className="container">
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarText"
          >
            <Scrollspy
              offset={-56}
              className="navbar-nav"
              items={[
                "welcome",
                "schedule",
                "committee",
                "registration",
                "city-attraction",
                "contact",
              ]}
              currentClassName="is-current"
            >
              <li className="nav-item">
                <a className="nav-link" href="#welcome">
                  Welcome Message
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#committee">
                  Committee
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#schedule">
                  Schedule
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#registration">
                  Registration
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#city-attraction">
                  Gujarat Attraction
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
            </Scrollspy>
          </div>
        </div>
      </nav>
    );
  }
}
