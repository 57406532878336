import React, { useState } from "react";

const Countdown = () => {
    const [showDays, showDaysHandler] = useState("");
    const [showHours, showHoursHandler] = useState("");
    const [showMinutes, showMinutesHandler] = useState("");
    const [showSeconds, showSecondsHandler] = useState("");

    setInterval(() => {
        var dateString = "2021-12-17 09:00:00";
        var date_future = new Date(dateString.replace(" ", "T"));
        // const date_future = new Date(("2020-01-10 09:00:00"));
        const date_now = new Date();

        let seconds = Math.floor((date_future - date_now) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds =
            seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        showDaysHandler(days);
        showHoursHandler(hours);
        showMinutesHandler(minutes);
        showSecondsHandler(seconds);
    }, 1000);

    return (
        <div className="bg-primary Countdown py-5">
            <div className="container">
                <ul className="list-unstyled m-0 d-block text-center d-md-flex justify-content-center">
                    <li className="d-flex align-items-end mr-3 justify-content-center">
                        <h3 className="mb-0 Countdown__Title font-weight-bold mr-2">
                            {showDays}
                        </h3>
                        <small className="text-uppercase">
                            {" "}
                            {showDays <= 1 ? "Day" : "Days"}
                        </small>
                    </li>
                    <li className="d-flex align-items-end mr-3 justify-content-center">
                        <h3 className="mb-0 Countdown__Title font-weight-bold mr-2">
                            {showHours}
                        </h3>
                        <small className="text-uppercase">
                            {" "}
                            {showHours <= 1 ? "Hour" : "Hours"}
                        </small>
                    </li>
                    <li className="d-flex align-items-end mr-3 justify-content-center">
                        <h3 className="mb-0 Countdown__Title font-weight-bold mr-2">
                            {showMinutes}
                        </h3>
                        <small className="text-uppercase">
                            {" "}
                            {showMinutes <= 1 ? "Minute" : "Minutes"}
                        </small>
                    </li>
                    <li className="d-flex align-items-end mr-3 justify-content-center">
                        <h3 className="mb-0 Countdown__Title font-weight-bold mr-2">
                            {showSeconds}
                        </h3>
                        <small className="text-uppercase">
                            {" "}
                            {showSeconds <= 1 ? "Second" : "Seconds"}
                        </small>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Countdown;
