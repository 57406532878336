import React, { useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";

import cityAttractionData from "./CityAttrectionData";
import Heading from "../../Helper/Heading";

import "./CityAttraction.scss";

const CityAttraction = () => {
    const [currentImage, setCurrentImage] = useState("");
    const [initPhotoIndex, setPhotoIndex] = useState(0);

    const handleClickImage = (e, image) => {
        e && e.preventDefault();
        setCurrentImage(image);
    };

    const handleCloseModal = (e) => {
        e && e.preventDefault();
        setCurrentImage("");
    };

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 1000,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <div className="container pt-5 pb-5" id="city-attraction">
                <div className="text-center mb-5">
                    <Heading title={"Gujarat Attraction"} />
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <h4 className="text-secondary font-weight-bold">
                            About Gujarat
                        </h4>
                        <p className="text-justify">
                            Aavo Padharo, words of welcome in the language of
                            Gujarat because it is here that these words ring
                            truly and the guest is 'God' and the people of
                            Gujarat are gregariously friendly, inviting and will
                            entice you to come again and again. Stretches out
                            into the Arabian Sea, with a hint of the desert and
                            with a coastline of 1600 km. long is Gujarat – the
                            home state of Mahatma Gandhi, the Father of Nation.
                            It is renowned for its beaches, temple towns and
                            historic capitals. Wildlife sanctuaries, hill
                            resorts and natural grandeur are gifts of Gujarat.
                            Sculpture, handicrafts, arts, festivals also make
                            the state rich.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="Image__Slider city-attraction-slider w-100">
                        <Slider {...settings} className="mx-auto">
                            {cityAttractionData.map((item, i) => (
                                <div className="text-center mb-3 p-3" key={i}>
                                    <h4 className="mt-2 mb-2">{item.title}</h4>
                                    <img
                                        src={item.imageThumb}
                                        className="img-fluid mx-auto city-attraction-image"
                                        alt="GUJASICON"
                                        onClick={(e) =>
                                            handleClickImage(e, item.imageFull)
                                        }
                                    />
                                    <p className="mt-3">{item.description}</p>
                                </div>
                            ))}
                        </Slider>
                        {currentImage && (
                            <Lightbox
                                mainSrc={currentImage}
                                nextSrc={
                                    cityAttractionData[
                                        (initPhotoIndex + 1) %
                                            cityAttractionData.length
                                    ].imageFull
                                }
                                prevSrc={
                                    cityAttractionData[
                                        (initPhotoIndex +
                                            cityAttractionData.length -
                                            1) %
                                            cityAttractionData.length
                                    ].imageFull
                                }
                                onMovePrevRequest={() => {
                                    setPhotoIndex(
                                        (initPhotoIndex +
                                            cityAttractionData.length -
                                            1) %
                                            cityAttractionData.length
                                    );
                                    setCurrentImage(
                                        cityAttractionData[
                                            (initPhotoIndex +
                                                cityAttractionData.length -
                                                1) %
                                                cityAttractionData.length
                                        ].imageFull
                                    );
                                }}
                                onMoveNextRequest={() => {
                                    setPhotoIndex(
                                        (initPhotoIndex + 1) %
                                            cityAttractionData.length
                                    );
                                    setCurrentImage(
                                        cityAttractionData[
                                            (initPhotoIndex + 1) %
                                                cityAttractionData.length
                                        ].imageFull
                                    );
                                }}
                                onCloseRequest={() => handleCloseModal()}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="city-attraction-bg" />
        </>
    );
};

export default CityAttraction;
