import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Drawer, Icon } from "antd";
import Scrollspy from "react-scrollspy";

import Logo from "../../../assets/images/logo.png";
import { CalendarIcon, LocationIcon, DochtubLogo } from "../../Helper/Icons";
import FlashMessage from "../../Shared/UI/FlashMessage";

const Banner = () => {
  const [visible, handleDrawer] = useState(false);

  const showDrawer = () => {
    handleDrawer(true);
  };

  const onClose = () => {
    handleDrawer(false);
  };

  return (
    <React.Fragment>
      <div className="Banner__Image d-flex align-items-center">
        <div className="container">
          <div className="text-center">
            <Link className="navbar-brand mb-3" to="/">
              <img
                width="132"
                src={Logo}
                srcSet={`${Logo} 2x`}
                alt="logo"
                title="TESCON"
              />
            </Link>
            <h1 className="font-weight-bold text-uppercase text-yellow mb-0">
              TESCON 2020
            </h1>
            <h2 className="text-white">
              Come, Share, Learn, and Expand Horizons
            </h2>
            <p className="lead font-weight-bold text-white">
              5th National Conference of Thoracic Endoscopy Society
            </p>
            <ul className="list-unstyled d-flex justify-content-center">
              <li className="d-flex icon-list-item">
                <CalendarIcon style={{ color: "#fff", fontSize: "50px" }} />
                <div className="ml-2 text-nowrap">
                  <p className="mb-0 font-weight-bold text-center text-yellow">
                    17 - 19
                  </p>
                  <span className="font-weight-bold text-white">
                    December, 2021
                  </span>
                </div>
              </li>
              <li className="d-flex ml-5 icon-list-item">
                <LocationIcon style={{ color: "#fff", fontSize: "50px" }} />
                <div className="ml-2">
                  <p className="text-left mb-0 font-weight-bold text-yellow">
                    <address>
                      Narayani Heights Hotel & Club, <br /> Bhat, Gandhinagar,
                      Gujarat
                    </address>
                  </p>
                </div>
              </li>
            </ul>
            <h2 className="mb-0 font-weight-bold  text-center text-white">
              Supported By: Association Of Chest Physicians Of Gujarat
            </h2>

            <div className="mt-4 mb-5">
              <a
                className="ant-btn ant-btn-primary ant-btn-lg text-white"
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_EVENT_WEB_REGISTRATION_LINK}
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-block d-lg-none">
        <Button type="primary" onClick={showDrawer} className="Drawer__Button">
          <Icon type="menu-unfold" />
        </Button>
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <nav>
            <Scrollspy
              className="navbar-nav"
              items={[
                "welcome",
                "schedule",
                "committee",
                "registration",
                "city-attraction",
                "contact",
              ]}
              currentClassName="is-current"
            >
              <li className="nav-item">
                <a className="nav-link" href="#welcome">
                  Welcome Message
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#schedule">
                  Schedule
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#committee">
                  Committee
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gallery">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#registration">
                  Registration
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#city-attraction">
                  City Attraction
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
            </Scrollspy>
          </nav>
          <div className="pt-5">
            <p className="lead font-weight-bold mb-0 lead">
              {" "}
              Digital Partner :
            </p>
            <a
              rel="noopener noreferrer"
              href="https://www.docthub.com/"
              className="d-inline-block link"
              target="_blank"
            >
              <DochtubLogo style={{ fontSize: "120px" }} />
              <p className="mb-0">Empowering Healthcare Fraternity</p>
              www.docthub.com
            </a>
          </div>
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default Banner;
