import React from "react";

import drParthivMehta from "../../../assets/images/commitees/Tescon Committee/Rectangle 1310.png";
import drHarjitDumra from "../../../assets/images/commitees/Tescon Committee/Rectangle 1316.png";
import drAmitPatel from "../../../assets/images/commitees/Tescon Committee/Rectangle 1322.png";
import drNalinShah from "../../../assets/images/commitees/Tescon Committee/Rectangle 1328.png";

import drAmrishPatel from "../../../assets/images/commitees/Tescon Committee/drAmrishPatel.png";
import drPradipDabhi from "../../../assets/images/commitees/Tescon Committee/drPradipDabhi.png";
import drJitendraKotadiya from "../../../assets/images/commitees/Tescon Committee/drJitendraKotadiya.png";
import drPranavModi from "../../../assets/images/commitees/Tescon Committee/drPranavModi.png";
import drSoniaDalal from "../../../assets/images/commitees/Tescon Committee/drSoniaDalal.png";
import drKiranRami from "../../../assets/images/commitees/Tescon Committee/drKiranRami.png";
import drTusharPatel from "../../../assets/images/commitees/Tescon Committee/drTusharPatel.png";
import drAmitDave from "../../../assets/images/commitees/Tescon Committee/drAmitDave.png";
import drNiharPatel from "../../../assets/images/commitees/Tescon Committee/drNiharPatel.png";
import drUmangShah from "../../../assets/images/commitees/Tescon Committee/drUmangShah.png";

const ImageCommittee = ({ src }) => {
  return <img src={src} alt="Comittee" className="rounded-circle mx-auto" />;
};

const scientificCommitteeData = [
  {
    name: "Dr. Parthiv Mehta",
    designation: "Scientific Commitee",
    img: () => {
      return <ImageCommittee src={drParthivMehta} />;
    },
  },

  {
    name: "Dr. Harjit Dumra",
    designation: "Scientific Commitee",
    img: () => {
      return <ImageCommittee src={drHarjitDumra} />;
    },
  },

  {
    name: "Dr. Amit Patel",
    designation: "Scientific Commitee",
    img: () => {
      return <ImageCommittee src={drAmitPatel} />;
    },
  },

  {
    name: "Dr. Nalin Shah",
    designation: "Scientific Commitee",
    img: () => {
      return <ImageCommittee src={drNalinShah} />;
    },
  },

  {
    name: "Dr. Amrish Patel",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drAmrishPatel} />;
    },
  },

  {
    name: "Dr. Pradip Dabhi",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drPradipDabhi} />;
    },
  },

  {
    name: "Dr. Jitendra Kotadiya",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drJitendraKotadiya} />;
    },
  },

  {
    name: "Dr. Pranav Modi",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drPranavModi} />;
    },
  },

  {
    name: "Dr. Sonia Dalal",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drSoniaDalal} />;
    },
  },

  {
    name: "Dr. Kiran Rami",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drKiranRami} />;
    },
  },

  {
    name: "Dr. Tushar Patel",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drTusharPatel} />;
    },
  },

  {
    name: "Dr. Amit Dave",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drAmitDave} />;
    },
  },

  {
    name: "Dr. Nihar Patel",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drNiharPatel} />;
    },
  },

  {
    name: "Dr. Umang Shah",
    designation: "Workshop Commitee",
    img: () => {
      return <ImageCommittee src={drUmangShah} />;
    },
  },
];

export default scientificCommitteeData;
