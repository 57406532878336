import React from "react";
import Heading from "../../Helper/Heading";
import image_1 from "../../../assets/images/image_1.jpg";
import image_2 from "../../../assets/images/image_2.png";
import image_3 from "../../../assets/images/image_3.jpg";
import Committee from "../Committee/Committee";
import ExecutiveCommitee from "../ExecutiveCommiteeNames/ExecutiveCommitee";

const WelcomeMessage = () => {
  return (
    <div className="py-5" id="welcome">
      <div className="container">
        <div className="text-center mb-5">
          <Heading title={"Invitation"} />
        </div>
        <p>
          Greeting for the day <br />
          After the long covid pandemic this medically marvelous Year will
          witness the fifth TESCON and promises to be bigger and better ever.
        </p>
        <p>
          On behalf of organising committee we cordially invite you to the land
          of SARDAR, MANCHESTER of India , AHMEDABAD for TESCON 2020.
        </p>
        <p>Now it's time to boat from online classes to offline meetings. </p>
        <p>
          The field of interventional Pulmonology continues to expand and
          develop at Rapid pace. This conference will be a feast on the learning
          pathway.
        </p>
        <p>
          it will provide an opportunity to learn future prospects and
          developmental activities in interventional pulmonology. During this
          session we promise to enrich fraternity with more promising outcome.
          This learning venture will have workshops on 17th of December followed
          by lectures on 18th and 19th of December.
        </p>
        We ensure that your stay in Ahmedabad, centre of Vibrant Gujarat would
        not only be wonderful but also full of long-lasting lingering memories
        .. <br />
        <br /> Dr. Varun Patel <br /> Organising Secretory <br />
        TESCON 2020
        <Committee />
        <ExecutiveCommitee />
        <div className="text-center m-5">
          <Heading
            title={"Welcome Message"}
            classNames={"text-uppercase font-weight-bold"}
          />
        </div>
        <div className="d-flex justify-content-around mt-5 flex-wrap">
          <div className="text-center mx-2">
            <div className="avatar-md-container mx-auto">
              <img
                src={image_1}
                className="rounded-circle mx-auto "
                alt="Committee"
              />
            </div>
            <p className="mt-3">
              <span className="mb-0 text-black mt-3 font-weight-bold lead">
                Dr. SK Sarkar{" "}
              </span>
              <br className="d-none d-md-block" /> <small>President</small>
            </p>
          </div>
          <div className="text-center mx-2">
            <div className="avatar-md-container mx-auto">
              <img
                src={image_2}
                className="rounded-circle mx-auto "
                alt="Committee"
              />
            </div>
            <p className="mt-3">
              <span className="mb-0 text-black mt-3 font-weight-bold lead">
                Dr. Ram Babu Gupta{" "}
              </span>
              <br className="d-none d-md-block" /> <small>Secretary</small>
            </p>
          </div>
          <div className="text-center mx-2">
            <div className="avatar-md-container mx-auto">
              <img
                src={image_3}
                className="rounded-circle mx-auto "
                alt="Committee"
              />
            </div>
            <p className="mt-3">
              <span className="mb-0 text-black mt-3 font-weight-bold lead">
                Dr. R P Meena{" "}
              </span>
              <br className="d-none d-md-block" /> <small>Treasurer</small>
            </p>
          </div>
        </div>
        <div className="container">
          <p className="mt-3">
            It is our immense pleasure to invite you in,"
            <span className="font-weight-bold"> TESCON 2020 </span>", the 5th
            annual conference of Thoracic Endoscopy Society from 17th to 19th
            December 2021 at India's 1st Heritage City - Ahmedabad.
          </p>

          <p>
            The theme of congress is "Come, Share, Learn and Expand Horizons” in
            Pulmonology and related subjects.
          </p>

          <p>
            Border of fields of Interventional Pulmonology is expanding day by
            day at rapid pace, this 1+2 day TESCON will focus on best practices
            in field of pulmonology and further expansion of other related
            specialities as well. The congress is a great platform for
            bronchoscopist, thoracoscopist, interventional pulmonologist and
            allied professionals like ENT Specialists, Radiologists and
            Gastroenterologists, in India to share their experiences, update
            their knowledge, and interact with fellow colleagues. Experts of
            each field will discuss on disease specific interventions with case-
            based approach to learn art of medicine. As Intervention is
            combination of Art and Science focusing to serve mankind, discussion
            based on latest evidence-based approaches through didactic lectures.
          </p>

          <p>
            We are proud to bring you a great synergy of experts across the
            globe who are basically scientists and pioneers in field of
            interventional pulmonology through TESCON 2020. The scientific
            programme is targeted to provide tangible benefits and networking
            opportunities to current pulmonologist. It will also provide
            tremendous environment for early career oriented members to learn
            about future prospects and professional development activities.
          </p>

          <p>
            TESCON 2020 is mentored by a host of distinguished International and
            National faculties with decades of experience in the field of
            interventional pulmonology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
