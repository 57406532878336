import React from "react";
import Slider from "react-slick";
import Heading from "../../Helper/Heading";
import brochurePdf from "../../../assets/pdf/brochure.pdf";
import { DownloadIcon } from "../../Helper/Icons";

const FEES_VALUE = {
  earlyBirds: {
    name: "Early Bird",
    date: (
      <>
        UP TO 15<sup>th</sup> NOV 2021
      </>
    ),
    feesPrice: [
      {
        name: "TES Member",
        price: "INR 6000",
      },
      {
        name: "Non TES Member",
        price: "INR 8000",
      },
      {
        name: "PG Student",
        price: "INR 4000",
      },
      {
        name: "Workshop",
        price: "INR 3000",
      },
      {
        name: "Accompanying Person",
        price: "INR 6000",
      },
      {
        name: "Industry Professionals Conference",
        price: "INR 12000",
      },
      {
        name: "Industry Professionals Workshop",
        price: "INR 6000",
      },
    ],
  },
  regular: {
    name: "Regular",
    date: (
      <>
        UP TO 07<sup>th</sup> DEC 2021
      </>
    ),
    feesPrice: [
      {
        name: "TES Member",
        price: "INR 8000",
      },
      {
        name: "Non TES Member",
        price: "INR 10000",
      },
      {
        name: "PG Student",
        price: "INR 6000",
      },
      {
        name: "Workshop",
        price: "INR 5000",
      },
      {
        name: "Accompanying Person",
        price: "INR 8000",
      },
      {
        name: "Industry Professionals Conference",
        price: "INR 15000",
      },
      {
        name: "Industry Professionals Workshop",
        price: "INR 8000",
      },
    ],
  },
  spot: {
    name: "Spot",
    date: (
      <>
        UP TO 18<sup>th</sup> December, 2021
      </>
    ),
    feesPrice: [
      {
        name: "TES Member",
        price: "INR 12000",
      },
      {
        name: "Non TES Member",
        price: "INR 15000",
      },
      {
        name: "PG Student",
        price: "INR 10000",
      },
      {
        name: "Workshop",
        price: "INR 10000",
      },
      {
        name: "Accompanying Person",
        price: "INR 12000",
      },
      {
        name: "Industry Professionals Conference",
        price: "INR 20000",
      },
      {
        name: "Industry Professionals Workshop",
        price: "INR 15000",
      },
    ],
  },
};

const Fees = () => {
  var settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="container py-5" id="registration">
      <div className="text-center">
        <Heading title={"Registration Fees"} />
      </div>

      {/**  large screen visible */}
      <div className="mt-5 d-none d-lg-block">
        <h5 className="font-weight-bold">Non Residential</h5>
        <div className="d-md-flex justify-content-between Fees__Table">
          <ul className="Title__Column Fees__List list-unstyled mb-0 registration_type">
            <li
              className="w-100 Fees__Table__Heading d-flex align-items-center"
              style={{ height: "80px" }}
            >
              <p className="font-weight-bold text-black mb-0 lead text-primary">
                Registration Type
              </p>
            </li>
            {FEES_VALUE.earlyBirds.feesPrice.map((fee) => {
              return (
                <li className="text-primary font-weight-bold">{fee.name}</li>
              );
            })}
          </ul>
          {/* <ul className="Title__Column Fees__List Fees__List-early-birds list-unstyled w-100 mb-0 text-center"> */}
          {/* <li className="Fees__Table__Heading">
              <span>
                <p className="font-weight-bold text-primary mb-0 lead">
                  {FEES_VALUE.earlyBirds.name}
                </p>
                <p className="font-weight-bold mb-0">
                  {" "}
                  {FEES_VALUE.earlyBirds.date}
                </p>
              </span>
            </li> */}
          {/* {FEES_VALUE.earlyBirds.feesPrice.map((fee) => {
              return (
                <li className="text-primary font-weight-bold">{fee.price}</li>
              );
            })} */}
          {/* <li className="font-weight-bold">
                            <span>{FEES_VALUE.earlyBirds.delegates}</span>
                        </li>
                        <li className="font-weight-bold">
                            <span>{FEES_VALUE.earlyBirds.pgStudent}</span>
                        </li>
                        <li className="font-weight-bold">
                            <span>
                                {
                                    FEES_VALUE.earlyBirds.foreignDelegate.split(
                                        "-"
                                    )[0]
                                }
                                <small>
                                    {
                                        FEES_VALUE.earlyBirds.foreignDelegate.split(
                                            "-"
                                        )[1]
                                    }
                                </small>
                            </span>
                        </li>
                        <li className="font-weight-bold">
                            <span>{FEES_VALUE.earlyBirds.accPerson}</span>
                        </li> */}
          {/* </ul> */}
          <ul className="Title__Column Fees__List list-unstyled w-100 mb-0 text-center">
            <li className="Fees__Table__Heading">
              <p className="text-primary mb-0 lead">
                {FEES_VALUE.regular.name}
              </p>
              <p className="mb-0">{FEES_VALUE.regular.date}</p>
            </li>
            {FEES_VALUE.regular.feesPrice.map((fee) => {
              return (
                <li className="text-primary font-weight-bold">{fee.price}</li>
              );
            })}
            {/* <li className="font-weight-bold">
                            <span>{FEES_VALUE.regular.delegates}</span>
                        </li>
                        <li className="font-weight-bold">
                            <span>{FEES_VALUE.regular.pgStudent}</span>
                        </li> */}
          </ul>
          <ul className="Title__Column Fees__List list-unstyled w-100 mb-0 text-center">
            <li
              className="w-100 Fees__Table__Heading bg-blue d-flex align-items-center justify-content-center flex-column"
              style={{ height: "80px" }}
            >
              <p className="lead text-primary mb-0 d-block">
                {FEES_VALUE.spot.name}
              </p>
              <p className="mb-0">{FEES_VALUE.spot.date}</p>
            </li>
            {FEES_VALUE.spot.feesPrice.map((fee) => {
              return (
                <li className="text-primary font-weight-bold">{fee.price}</li>
              );
            })}
            {/* <li className="font-weight-bold">
                            <span>{FEES_VALUE.spot.delegates}</span>
                        </li>
                        <li className="font-weight-bold">
                            <span>{FEES_VALUE.spot.pgStudent}</span>
                        </li>
                        <li className="font-weight-bold">
                            <span>
                                {FEES_VALUE.spot.foreignDelegate.split("-")[0]}
                                <small>
                                    {
                                        FEES_VALUE.spot.foreignDelegate.split(
                                            "-"
                                        )[1]
                                    }
                                </small>
                            </span>
                        </li>
                        <li className="font-weight-bold">
                            <span>{FEES_VALUE.spot.accPerson}</span>
                        </li> */}
          </ul>
        </div>
      </div>

      {/** small screen visible */}
      <div className="d-block d-lg-none justify-content-between Fees__Table mt-5">
        <h5 className="font-weight-bold">Non Residential</h5>
        <Slider {...settings}>
          {/* <div className="Title__Column Fees__List Fees__List-early-birds list-unstyled w-100 text-center mb-3">
            <div className="Fees__Table__Heading bg-blue">
              <p className="font-weight-bold text-primary mb-0 lead">
                {FEES_VALUE.earlyBirds.name}
              </p>
              <p className="font-weight-bold mb-0">
                {FEES_VALUE.earlyBirds.date}
              </p>
            </div>
            <table className="table table-bordered m-0">
              <tbody>
                {FEES_VALUE.earlyBirds.feesPrice.map((fee) => {
                  return (
                    <tr>
                      <th scope="row">{fee.name}</th>
                      <td colSpan="2">{fee.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
          <div className="Title__Column Fees__List list-unstyled w-100 mb-3 text-center">
            <div className="Fees__Table__Heading ">
              <p className="font-weight-bold text-primary mb-0 lead">
                {FEES_VALUE.regular.name}
              </p>
              <small>{FEES_VALUE.regular.date}</small>
            </div>
            <table className="table table-bordered m-0">
              <tbody>
                {FEES_VALUE.regular.feesPrice.map((fee) => {
                  return (
                    <tr>
                      <th scope="row">{fee.name}</th>
                      <td colSpan="2">{fee.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="Title__Column Fees__List list-unstyled w-100 mb-0 text-center">
            <div
              className="w-100 Fees__Table__Heading d-flex align-items-center justify-content-center flex-column"
              style={{ height: "60px" }}
            >
              <p className="lead text-primary mb-0">{FEES_VALUE.spot.name}</p>
              <small>{FEES_VALUE.spot.date}</small>
            </div>
            <table className="table table-bordered m-0">
              <tbody>
                {FEES_VALUE.spot.feesPrice.map((fee) => {
                  return (
                    <tr>
                      <th scope="row">{fee.name}</th>
                      <td colSpan="2">{fee.price}</td>
                    </tr>
                  );
                })}
                {/* <tr>
                                    <th scope="row">Delegates</th>
                                    <td colSpan="2">
                                        {FEES_VALUE.spot.delegates}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">PG Student</th>
                                    <td colSpan="2">
                                        {FEES_VALUE.spot.pgStudent}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Foreign Delegates</th>
                                    <td colSpan="2">
                                        {FEES_VALUE.spot.foreignDelegate
                                            .split("-")
                                            .join("")}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Accompanying Person <br />
                                        (Spouse Indian)
                                    </th>
                                    <td colSpan="2">
                                        {FEES_VALUE.spot.accPerson}
                                    </td>
                                </tr> */}
              </tbody>
            </table>
          </div>
        </Slider>
      </div>

      <p className="mt-3">
        <b>Note: </b>18% GST & Convenience Fee will be extra
      </p>

      {/* <div className="text-center mt-5">
                <a
                    className="ant-btn ant-btn-primary ant-btn-lg text-white"
                    href={process.env.REACT_APP_EVENT_WEB_REGISTRATION_LINK}
                >
                    Register Now
                </a>
            </div> */}
      <div className="mt-5">
        <p className="lead font-weight-bold mb-0">Online Registration :</p>
        <br />
        <li>Click on Register Now</li>
        <li>Create or login to Docthub</li>
        <li>Select Tickets & add attendee details</li>
        <li>Pay Online & get an invoice in email</li>
        <li>Get access your invoice & event details in your Docthub Account</li>
        <li>
          Get access to healthcare professional network & services on Docthub
          platform
        </li>
        <li>
          For online registration assistance, contact Docthub events team on +91
          8320875943
        </li>
        <br />

        <p className="lead font-weight-bold mb-0">Offline Registration :</p>
        <p className="mb-1">
          - Please download a form and send fully filled form along with
          registration fees to Secretariat Office address (as mentioned in form)
        </p>
        <p className="mb-1">- RTGS/ NEFT details</p>
        <p className="mb-1 pl-2">
          <b>Account Name:</b> TESCON 2020 <br />
          <b>Account Number:</b> 50507841130 <br />
          <b>IFSC code:</b> IDBI000M364 <br />
          <b>Bank:</b> Indian Bank <br />
          <b>Branch:</b> LCB, S P Nagar, Ahmedabad
        </p>
        <a
          href={brochurePdf}
          target="_blank"
          className="ant-btn link font-weight-bold mt-2"
          rel="noopener noreferrer"
        >
          {" "}
          <DownloadIcon className="mr-1" /> DOWNLOAD FORM{" "}
        </a>
      </div>
    </div>
  );
};

export default Fees;
