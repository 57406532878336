import StatueOfUnity from "../../../assets/images/city-attrection/statue-of-unity.jpg";
import StatueOfUnityThumb from "../../../assets/images/city-attrection/statue-of-unity-thumb.png";

import rannOfKutch from "../../../assets/images/city-attrection/rann-of-kutch.png";
import somnath from "../../../assets/images/city-attrection/somnath.png";

import dwarka from "../../../assets/images/city-attrection/dwarka.png";

const cityAttractionData = [
    {
        title: "Statue of Unity",
        imageThumb: StatueOfUnityThumb,
        imageFull: StatueOfUnity,
        description: `The Statue of Unity is the tallest Statue of the World with a height of 182 meters.
                      It is the statue of Iron Man of India. Sardar Vallabhbhai Patel. It is located near Sardar
                      Sarovar Dam on river Narmada at Kevadiya Colony. 200 km from Ahmedabad.`,
    },
    {
        title: "Somnath",
        imageThumb: somnath,
        imageFull: somnath,
        description: `Shree Somnath is first among the twelve Aadi Jyotirlings of India. It has a strategic location on the western coast of India.
        Ancient Indian traditions maintain a close relationship of Somnath with release of Chandra (Moon God) from the curse of his father-in-law Daksha Prajapati.`,
    },
    {
        title: "Dwarka",
        imageThumb: dwarka,
        imageFull: dwarka,
        description: `The main draw for tourists coming to Dwarka, the Dwarkadhish Temple (Jagat Mandir), is believed to have been established more than 2500 years ago by Lord Krishna’s great grandson, Vajranabh. The ancient temple has been renovated several times, especially leaving imprints of 16th and 19th centuries.
        `,
    },
    {
        title: "White Rann of Kutch",
        imageThumb: rannOfKutch,
        imageFull: rannOfKutch,
        description: `The Rann of Kachchh is a salt marshy land in the Thar Desert in the Kachchh district of western Gujarat. It lies between Gujarat in India and the Sindh province in Pakistan. It comprises of around 30,000 sq. km of land which includes The Great Rann of Kachchh, The Little Rann of Kachchh and Banni grassland.
        `,
    },
];

export default cityAttractionData;
